<template>
<div class="p-grid p-fluid home">
    <div class="home">
        <div class="features" style="text-align: center;background-image: url('./images/prefondo4.png');">
            <h3 style="color:#ffcc2a;">{{$t('Elija su mejor cobertura para usted')}}</h3>
            <h5 style="color:#ffff;">{{$t('Hazlo en SaBeé ”Protección financiera a la medida de sus necesidades”')}}</h5>
            <div class="p-grid" >
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <img alt="components" src="../assets/images/HomeInsurance.webp" width="220" height="110"/>
                        <div class="feature-card-detail">
                            <div class="feature-name">{{$t('Fire Insurance')}}</div>
                            <img style="width: 20%;" src="../assets/images/IconHome.webp" width="32" height="32"/>
                            <br>
                            <Button :label="$t('Más información')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(1)" v-if="!this.$store.state.authenticated"></Button>
                            <Button :label="$t('Cotización')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(1)" v-else></Button>
                            <p>{{$t('Fire Insurance Desc')}}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <img alt="opensource" src="../assets/images/VehicleInsurance.webp" width="220" height="110"/>
                        <div class="feature-card-detail">
                            <div class="feature-name">{{$t('Vehicle Insurance')}}</div>
                            <img style="width: 20%;" src="../assets/images/IconVehicle.webp" width="32" height="32"/>
                            <br>
                            <Button :label="$t('Más información')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(2)"  v-if="!this.$store.state.authenticated"></Button>
                            <Button :label="$t('Cotización')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(2)"  v-else></Button>
                            <p>{{$t('Vehicle Insurance Desc')}}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <img alt="themes" src="../assets/images/HealthInsurance.webp" width="220" height="110"/>
                        <div class="feature-card-detail">
                            <div class="feature-name">{{$t('Health Insurance')}}</div>
                            <img style="width: 20%;" src="../assets/images/IconHealth.webp" width="32" height="32"/>
                            <br>
                            <Button :label="$t('Más información')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(3)" v-if="!this.$store.state.authenticated"></Button>
                            <Button :label="$t('Cotización')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(3)" v-else></Button>
                            <p>{{$t('Health Insurance Desc')}}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <img alt="templates" src="../assets/images/BusinessInsurance.webp" width="220" height="110"/>
                        <div class="feature-card-detail">
                            <div class="feature-name">{{$t('Business Insurance')}}</div>
                            <img style="width: 20%;" src="../assets/images/IconBusiness.webp" width="32" height="32"/>
                            <br>
                            <Button :label="$t('Más información')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(4)" v-if="!this.$store.state.authenticated"></Button>
                            <Button :label="$t('Cotización')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(4)" v-else></Button>
                            <p>{{$t('Business Insurance Desc')}}</p>
                        </div>
                    </div>
                </div>
          </div>
        </div>
	</div>

    <div class="features" style="background:#727376;">
        <div class="p-grid" >
            <div class="p-col-12 p-md-8">
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 ">
                        <h4 style="color:#ffffff;"><strong>{{$t('¡No deje que su póliza expire!')}}</strong></h4>
                        <p style="color:#ffffff;"><strong>{{$t('cobertura')}}</strong></p>
                    </div>
                    <div class="p-col-12 p-md-12 ">
                        <h4 style="color:#ffffff;"><strong>{{$t('Estamos en todas partes para mantenerte cubierto')}}</strong></h4>
                        <p style="color:#ffffff;"><strong>{{$t('proteger')}}</strong></p>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-md-4">
                <div class="feature-card">
                    <img alt="templates" src="../assets/images/claim.webp"  width="220" height="110"/>
                    <div class="feature-card-detail">
                        <div class="feature-name">{{$t('Si está asegurado con nosotros')}}</div>
                        <p  style="font-size:12px;">{{$t('insured1')}}</p>
                        <p  style="font-size:12px;">{{$t('insured2')}}</p>
                        <p  style="font-size:12px;">{{$t('insured3')}}</p>
                        <p  style="font-size:12px;">{{$t('insured4')}}</p>
                        <p  style="font-size:12px;">{{$t('insured5')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

	<Dialog v-model:visible="MensajeDialog" :style="{width: size}" :header="$t('Mensaje')" :modal="true">
		<br>
        <div class="confirmation-content">
			<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
			<span> {{$t('Debe ingresar primero')}}</span>
		</div>
		<template #footer>
			<Button :label="$t('Cerrar')" icon="pi pi-times" class="p-button-danger" @click="MensajeDialog = false"/>
		</template>
	</Dialog>
	<Dialog v-model:visible="PerfilDialog" :style="{width: size}" :header="$t('Mi Perfil')" :modal="true">
		<br>
        <div class="confirmation-content">
			<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
			<span> {{$t('Actualiza Perfil')}}</span>
		</div>
		<template #footer>
			<Button :label="$t('Mi Perfil')" icon="pi pi-check" class="p-button-secondary" @click="Perfil" />
			<Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="PerfilDialog = false"/>
		</template>
	</Dialog>

</div>
</template>

<script>
import API from "../service/ApiConsulta";

export default {
	components: {
    },
	data() {
		return {
            MensajeDialog: false,
            size: '40vw',
            perfil: null,		
            PerfilDialog: false,
		}
	},
	productService: null,
	eventService: null,
	created() {
        if (this.$store.state.mobile){this.size = '90vw';}
        this.$store.commit('Validar',this.$route);
	},
	mounted() {
	},
	methods: {
        Opcion(caso){
            if(this.$store.state.authenticated){
                this.$store.commit('Loading');
                this.PerfilDialog = false;
                const Consulta = new API('Cotizacion');
                Consulta.Ini('Polizas').then(result => {
                    if(result.consult.perfil){
                        this.$store.commit('Loading');
                        this.$router.push({ path: '/quotations'+caso});
                    } else  {
                        this.$store.commit('Loading');
                        this.PerfilDialog = true;
                    }
                }); 
            } else {
                this.MensajeDialog = true;
            }
        },
	},
	computed: {
    }

}
</script>
