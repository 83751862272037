<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reporte')+': '+$t('Cotizaciones')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
                <DataTable :value="poliza" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" :paginator="true" :rows="25">
                    <template #header>
                        <div class="table-header">
                            <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
                            <Dropdown v-model="opcion2" :options="opciones2" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar2()" v-if="opcion"></Dropdown>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                            <Column :header="$t('Estatus')">
                                <template #body="slotProps">  
                                    <Knob v-model="slotProps.data.estado" :min="0" :max="3" :valueTemplate="(parseFloat(slotProps.data.estado)*100/3).toFixed(0)+'%'"  readonly="false" v-if="slotProps.data.estado<=3"/>
                                   {{slotProps.data.fecha}} 
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.estado_nombre}}</div>
                                    <div v-else>{{slotProps.data.estado_name}}</div>                       
                                </template>
                            </Column> 
                            <Column field="id" :header="$t('Nº')" :sortable="true">
                                <template #body="slotProps">
                                    {{slotProps.data.id}}                              
                                </template>
                            </Column>
                            <Column :header="$t('Tipo')" >
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.evaluacion.tipopoliza_nombre}}</div>
                                    <div v-else>{{slotProps.data.evaluacion.tipopoliza_name}}</div>
                                </template>
                            </Column>
                            <Column field="duracion_name" :header="$t('Duración')">
                                <template #body="slotProps" >
                                    <div>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{slotProps.data.duracion_nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{slotProps.data.duracion_name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Cuota')">
                                <template #body="slotProps">
                                    <div>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{slotProps.data.cuotas}} Cuotas
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{slotProps.data.cuotas}} Quotas
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Monto Cuota')">
                                <template #body="slotProps">
                                    {{moneda(parseFloat(slotProps.data.evaluacion.montofinal/slotProps.data.evaluacion.cuotas))}}

                                </template>
                            </Column>
                            <Column :header="$t('Monto Total')">
                                <template #body="slotProps">
                                    {{moneda(parseFloat(slotProps.data.evaluacion.montofinal))}} 
                                </template>
                            </Column>
                    <template #footer footerStyle="text-align:right">
                        <Button icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()" v-if="poliza.length>0"/>
                    </template>
                </DataTable>
			</div>
        </div>
    </div>
</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import XLS from "../service/XLS";

export default {
	components: {
    },
	data() {
		return {
			selectedCustomers: null,
			filters: {},
			i18n: null,
            caso: null,
            poliza: [],
			url: null,
			opcion: null,
			opciones: [],
			opcion2: {code: 0, nombre: 'Todos', name: 'All'},
			opciones2: [
				{code: 0, nombre: 'Todos', name: 'All'},
			],
            polizas: [],
            duracion: [],
            cuotas: [],
        }
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.$store.commit('Loading');
            const Consulta = new API('Reportes');
            Consulta.Ini('Report2').then(result => {
                //this.$store.state.error = result;
                if (result.consult.opciones){
                    this.opciones = result.consult.opciones;
                }
                if (result.consult.estados){
                    result.consult.estados.forEach(result => {
                        this.opciones2.push(result);
                    });
                }
                this.duracion = result.consult.duracion;
                this.cuotas = result.consult.cuotas;
                this.$store.commit('Loading');
            }); 
        },
		VerXLS(){
            const xls = new XLS();
            xls.Reporte2(this.poliza,this.$store.state.currency);
		},
        Buscar(){
			this.$store.commit('Loading');
			this.poliza = [];
			this.polizas = [];
            this.opcion2 = {code: 0, nombre: 'Todos', name: 'All'};
            const Consulta = new API('Reportes');
			Consulta.Procesar('Report2',{
				caso: 	'Buscar',
				opcion: this.opcion.code,
				}).then(response => {
					//this.$store.state.error = response;
                    if (response.result.poliza){
                        this.poliza = response.result.poliza;
                        this.polizas = response.result.poliza;
                    }
                    this.$store.commit('Loading');
                });
        },
        Buscar2(){
			this.$store.commit('Loading');
            this.poliza = [];
            if(this.opcion2.code>0){
                if(this.polizas.length>0){
                    this.poliza = this.polizas.filter(val => val.estado == this.opcion2.code);
                }
            } else {
                if(this.polizas.length>0){
                    this.poliza = this.polizas;
                }
            }
            this.$store.commit('Loading');
        },
        Ver(data){
            const doc = new PDF();
            doc.Certificado(data);
            //doc.CotizacionVeh(data);
        },
        close() {
            this.caso = null;
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},


	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
